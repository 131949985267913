import React from 'react';
import Layout from '../layouts/index';
import SEO from '../components/SEO';
import Jumbo from '../components/jumbotron/Jumbo';
import styled from 'styled-components';
import OperhauserSection from '../components/sections/OpernhauserSection';
import VitaSection from '../components/sections/VitaSection';
import UnterrichtSection from '../components/sections/UnterrichtSection';
import LinkSection from '../components/sections/LinkSection';

const Background = styled.main`
  background: ${(props) => props.theme.secondary};
  position: relative;
  z-index: 10;
`;

const IndexPage = ({ data: { galleryImages } }) => (
  <Layout>
    <SEO title="Home" />

    <Jumbo {...galleryImages} />

    <Background className="pt-4">
      <VitaSection />
      <OperhauserSection />
      <UnterrichtSection />
      <LinkSection />
    </Background>
  </Layout>
);

export const query = graphql`
  query IndexPageQuery {
    galleryImages: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      nodes {
        id
        childImageSharp {
          fluid(maxWidth: 400, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

export default IndexPage;
