export function random(min: number = 0, max: number): number {
  let rand = Math.random()

  if (typeof min === "undefined") {
    return rand
  } else if (typeof max === "undefined") {
    return rand * min
  } else {
    if (min > max) {
      const tmp = min
      min = max
      max = tmp
    }

    return rand * (max - min) + min
  }
}
