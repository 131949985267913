import React from 'react';
import styled from 'styled-components';
import Gallery from './gallery/Gallery';

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 70vh;
  width: 100%;
  position: relative;
  color: ${(props) => props.theme.primary};
  -webkit-text-stroke: 1px ${(props) => props.theme.secondary};
  /* text-shadow: 0.35rem 0 0 ${(props) => props.theme.secondary}; */
`;

const Title = styled.h1`
  letter-spacing: -0.5vw;
  font-size: 8vw;
  position: relative;
  z-index: 2;
  line-height: 0.4;

  @media only screen and (max-width: 900px) {
    font-size: 14vw;
  }
`;

const StyledSpan = styled.span`
  letter-spacing: -0.65vw;
  margin-top: -2vw;
  font-size: 8vw;
  font-family: 'Suisse Serif', 'Suisse', sans-serif;
  position: relative;
  z-index: 2;

  @media only screen and (max-width: 900px) {
    font-size: 14vw;
    margin-top: -5vw;
  }
`;

const Jumbo = (galleryImages) => (
  <StyledHeader className="m-0 pt-5">
    <Gallery {...galleryImages} />

    <div className="text-center">
      <Title className="display-1">
        Michaela Lucas
        <br />
        <StyledSpan className="display-1">Mezzosopran</StyledSpan>
      </Title>
    </div>
  </StyledHeader>
);

export default Jumbo;
