import React from 'react';
import { vita } from '../../../data';

const VitaSection = () => (
  <section className="container pb-5 px-4 pt-1">
    <h2>Vita</h2>
    <p>{vita}</p>
  </section>
);

export default VitaSection;
