import React from 'react';
import styled from 'styled-components';
import GalleryImage from './GalleryImage';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Gallery = (galleryImages) => (
  <Wrapper>
    {galleryImages.nodes.map((image) => (
      <GalleryImage key={image.id} src={image.childImageSharp.fluid} />
    ))}
  </Wrapper>
);

export default Gallery;
