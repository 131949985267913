import GatsbyImg from 'gatsby-image';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { random } from '../../../utils/random';

const Wrapper = styled(animated.div)`
  position: fixed;
  height: 13vw;
  width: 13vw;
  filter: brightness(1.2) contrast(80%);

  @media only screen and (max-width: 900px) {
    height: 30vh;
    width: 30vh;
  }
`;

const GalleryImage = ({ src }) => {
  const anim = useSpring({
    from: { transform: 'translate(0px, 0px) scale(0)', opacity: 0 },
    to: {
      transform: `translate(${random(-500, 500)}px, 
      ${random(-250, 250)}px) scale(${random(0.8, 1.4)})`,
      opacity: 1,
    },
  });

  return (
    <Wrapper style={anim}>
      <GatsbyImg fluid={src} draggeable={false} alt="Bühnenfoto" />
    </Wrapper>
  );
};

export default GalleryImage;
