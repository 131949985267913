import React from 'react';
import { unterricht } from '../../../data';

const UnterrichtSection = () => (
  <section className="container pb-5 px-4 pt-1">
    <h2>Unterricht</h2>
    <p>{unterricht}</p>
  </section>
);

export default UnterrichtSection;
