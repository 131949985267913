import React from 'react';
import { opernhauser } from '../../../data';

const OpernhauserSection = () => (
  <section className="container pb-5 px-4 pt-1">
    <h2>Opernhäuser</h2>
    <ul>
      {opernhauser.map(({ name, link }, i) => (
        <li key={name.replace(/\s+/g, '-').toLowerCase() + i.toString()}>
          <a href={link}>{name}</a>
        </li>
      ))}
    </ul>
  </section>
);

export default OpernhauserSection;
