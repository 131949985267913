import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background: ${(props) => props.theme.secondary};
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;

  p,
  a {
    font-size: 0.8rem;
    margin: 0;
  }

  a::after {
    content: '↗';
  }

  @media only screen and (max-width: 900px) {
    align-items: center;
    flex-wrap: wrap;
    flex-flow: column;

    a:not(p > a) {
      margin-bottom: 1rem;
    }
  }
`;

const Footer = () => (
  <StyledFooter className="container p-4">
    <a href="mailto:michaelalucas@gmx.de">Email</a>

    <a href="https://www.facebook.com/ml.mezzosopran/">Facebook</a>

    <p>© {new Date().getFullYear()} Michaela Lucas</p>
  </StyledFooter>
);

export default Footer;
