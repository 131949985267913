import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  background: ${(props) => props.theme.primary};

  * {
    color: ${(props) => props.theme.secondary};
  }

  a {
    margin-right: 1rem;
  }

  a:hover {
    color: ${(props) => props.theme.secondary};
  }
`;

const LinkSection = () => (
  <StyledSection className="container p-4">
    <h2>Kontakt</h2>
    <a href="mailto:#">Email</a>
    <a href="mailto:#">Facebook</a>
  </StyledSection>
);

export default LinkSection;
