import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';
import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Footer from '../components/Footer';
import './fonts.css';

const theme = {
  primary: 'black', // img coloration, type
  secondary: 'rgb(251, 251, 251)', // bg
};

const GlobalStyle = createGlobalStyle`
  /* global */

  ::selection {
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.secondary};
  }

  body {
    background: ${(props) => props.theme.secondary};
    font-family: "Suisse", helvetica, sans-serif;
    color: ${(props) => props.theme.primary};
  }

  /* typography */
  section {
    a:hover {
      font-family: "Suisse Serif";
      letter-spacing: -0.02em;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }

  p, li {
    font-size: 1.2rem;
  }

  a {
    color: ${(props) => props.theme.primary};
    text-decoration: underline;
  }

  a:hover {
    color: ${(props) => props.theme.primary};
  }


  p {
    line-height: 1.35;
  }

  small {
    letter-spacing: 0.025rem;
  }

  @media only screen and (max-width: 900px) {    
    p, li {
      font-size: 1rem;
    }
    
    p {
      columns: 1;
    }
  }

  /* layout */

section {
  ul, p {
   columns: 2;
   gap: 3rem;
 }
}

  @media only screen and (max-width: 900px) {
    section {
      ul, p {
      columns: 1;    
    }
    }
  }

  /* resets */

  ul,
  ul li,
  ul li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
    <Footer />
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
